import { Container } from 'typedi';
import { MouseEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { InternalAPI } from 'app/browser/window';
import { registerPopup } from 'app/browser/popups.actions';
import { LocalStorageRepository } from 'app/repositories/local-storage.repository';

export const usePopupWindow = (name: string) => {
  const dispatch = useDispatch();
  return useCallback((url: string) => (e: MouseEvent<any>) => {
    e.preventDefault();

    let options;
    const top = 10;
    const left = 10;
    const pageHeight = screen.availHeight - 70;
    const windowSettings = {
      width: 1086,
      height: pageHeight,
      toolbar: 'no',
      directories: 'no',
      menubar: 'no',
      status: 'yes',
      resizable: 'yes',
      location: 'no',
      scrollbars: 1,
      top: top,
      left: left
    };

    options = Object.entries(windowSettings).map(entry => entry.join('=')).join(',');

    const storage = Container.get(LocalStorageRepository);
    const appNameKey = Container.get('appConstants.appName');
    const api = Container.get<InternalAPI>('internalAPI');

    api.sessionStorage = {
      [`${appNameKey}.user`]: storage.get('user'),
      [`${appNameKey}.user_token`]: storage.get('user_token')
    };

    const popWin = window.open(url, name, options);
    if (popWin) {
      popWin.focus();
    }

    dispatch(registerPopup(name, popWin));
  }, [name]);
};
